<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Notify Drivers</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<template v-else>
				<div class="row">
					<div class="col-12 col-xl-8">
						<div
							class="alert alert-danger alert-dismissible fade show"
							role="alert"
							v-if="errorMessage"
							>
							{{ errorMessage }}
						</div>
						<form>
							<div class="form-group">
								<input
									v-model="notification.title"
									required
									type="text"
									class="form-control"
									placeholder="Notification title"
									/>
							</div>
							<div class="card">
								<div class="card-body">
									<div class="form-group">
										<textarea
											v-model="notification.description"
											class="form-control form-control-flush form-control-auto"
											data-autosize=""
											rows="3"
											placeholder="Start a notification..."
											style="
                        overflow: hidden;
                        overflow-wrap: break-word;
                        height: 69px;
                      "
											></textarea>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-between mt-2">
								<button
									@click.prevent="notifyDrivers('all')"
									class="btn btn-white border border-1"
									:disabled="processing || processingAll || buttonsDisabled"
									>
									{{ processingAll ? 'Processing...' : 'Notify All Drivers' }}
								</button>
							</div>
						</form>
					</div>
					<div class="col-12 col-xl-4">
						<div class="card selected-drivers">
							<div class="card-header">
								<div class="col px-0">
									<h4 class="card-header-title">Selected Drivers</h4>
								</div>
								<div class="col-auto px-0">
									<button
										@click.prevent="notifyDrivers('selected')"
										class="btn btn-primary btn-sm"
										:disabled="
											processing ||
												processingAll ||
												selectedDrivers.length === 0 ||
												buttonsDisabled
										"
										>
										{{
											processingCorporateNotification
												? 'Processing...'
												: 'Notify Selected Drivers'
										}}
									</button>
								</div>
							</div>
							<div class="card-body">
								<template v-if="selectedDrivers.length > 0">
									<div
										class="badge bg-light mr-2 mb-2"
										v-for="driver in selectedDrivers"
										:key="driver.id"
										>
										{{ driver.email }}
										<span
											@click="removeSelectedDriver(driver)"
											style="cursor: pointer"
											><i class="fe fe-x"></i
												></span>
									</div>
								</template>
								<template v-else>
									<p class="py-4 text-grey text-center">
										Selected drivers will show here
									</p>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12">
						<div class="card">
							<div class="card-header justify-content-between">
								<div
									class="input-group input-group-flush d-flex flex-row-reverse"
									>
									<input
										v-model.trim="search"
										class="form-control list-search"
										type="search"
										placeholder="Search"
										@keyup.enter.prevent="fetchDrivers(true)"
										/>
									<span class="input-group-text border-0">
										<i class="fe fe-search"></i>
									</span>
								</div>
							</div>
							<b-table
								striped
								hover
								selectable
								responsive
								:items="drivers"
								:fields="fields"
								:current-page="currentPage"
								:busy="loading"
								>
								<template #table-busy>
									<div class="text-center text-secondary my-2">
										<strong>Loading...</strong>
									</div>
								</template>
								<template #empty>
									<h4>There are no drivers</h4>
								</template>
								<template #head(checkAll)>
									<input
										v-model="notification.notifyAll"
										type="checkbox"
										id="notify-all"
										/>
								</template>
								<template #cell(name)="data">
									<p class="font-weight-bold" style="line-height: 1">
										<router-link
											class="sh-page-link"
											:to="{
												name: 'ShowDriver',
												params: { driverId: data.item.id },
											}"
											>{{
												`${data.item.fname} ${data.item.lname}`
											}}</router-link
											>
									</p>
								</template>
								<template #cell(checkAll)="data">
									<input
										:checked="
											selectedDrivers.find(
												(driver) => driver.id === data.item.id
											) || notification.notifyAll
										"
										@input="selectDriver(data.item)"
										type="checkbox"
										id="notify-driver"
										/>
								</template>
							</b-table>
							<div class="card-footer" v-if="totalRecords">
								<b-pagination
									v-model="currentPage"
									:total-rows="totalRecords"
									:per-page="perPage"
									></b-pagination>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
  props: ['userId'],
  data () {
    return {
      fields: [
        {
          key: 'checkAll',
          label: 'a'
        },
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'phone',
          label: 'Phone'
        }
      ],
      perPage: 40,
      currentPage: 1,
      totalRecords: 0,
      drivers: [],
      processing: false,
      processingAll: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false,
      companies: [],
      notification: {
        notifyAll: false,
        title: '',
        description: ''
      },
      search: '',
      selectedDrivers: [],
      processingCorporateNotification: false,
      buttonsDisabled: false
    }
  },
  created () {
    this.initDrivers(true)
  },
  computed: {
    filteredDrivers () {
      let drivers = this.drivers
      if (this.filter.corporateId) {
        drivers = this.drivers.filter(
          (driver) => driver.corporate_id === this.filter.corporateId
        )
      }
      const search = this.search.toLowerCase()
      return drivers.filter((driver) => {
        return (
          driver.fname.toLowerCase().includes(search) ||
          driver.lname.toLowerCase().includes(search) ||
          driver.email.toLowerCase().includes(search) ||
          driver.phone.toLowerCase().includes(search)
        )
      })
    }
  },
  watch: {
    currentPage () {
      this.fetchDrivers()
    },
    search (value) {
      if (!value) {
        this.fetchDrivers(true)
      }
    },
    'notification.notifyAll' (val) {
      if (val) {
        this.drivers.map((u) => {
          this.selectDriver(u)
        })
      } else {
        this.drivers.map((u) => {
          this.removeSelectedDriver(u)
        })
      }
    }
  },

  methods: {
    initDrivers () {
      this.loading = true
      Promise.all([this.fetchDrivers()])
        .catch(() => {
          this.$toastr.e(
            'Something went wrong while fetching drivers.',
            'Error'
          )
        })
        .finally(() => (this.loading = false))
    },
    fetchDrivers (reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      const isSearch = !!this.search
      const url = `/v1/drivers?limit=${this.perPage}&page=${this.currentPage}${
        isSearch ? `&search=${this.search}` : ''
      }&metadata=true&status=active&related=device`

      const request = this.axios.get(url)
      return request
        .then((res) => {
          if (this.notification.notifyAll) {
            res.data.data.map((i) => {
              this.selectDriver(i)
            })
          }
          this.drivers = res.data.data
          this.totalRecords = res.data?.metadata?.total
        })
        .catch(() => {
          this.$toastr.e('Something went wrong', 'Error')
        })
    },
    removeSelectedDriver (selectedUser) {
      const index = this.selectedDrivers.findIndex(
        (user) => user.id === selectedUser.id
      )
      this.selectedDrivers.splice(index, 1)
    },
    selectDriver (selectedUser) {
      const index = this.selectedDrivers.findIndex(
        (user) => user.id === selectedUser.id
      )
      if (index !== -1) {
        this.selectedDrivers.splice(index, 1)
      } else {
        this.selectedDrivers.push(selectedUser)
      }
    },
    async notifyDrivers (notificationType) {
      if (
        this.notification.title === '' ||
        this.notification.description === ''
      ) {
        return
      }
      const result = await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to notify ${notificationType} drivers?`,
        showConfirmButton: true,
        showCancelButton: true
      })
      if (!result.isConfirmed) {
        return
      }
      this.errorMessage = ''
      let payload = {
        title: this.notification.title,
        description: this.notification.description
      }
      if (notificationType === 'all') {
        payload = {
          ...payload,
          driver_ids: this.drivers.map((driver) => driver.id)
        }
        this.processingAll = true
      } else {
        payload = {
          ...payload,
          driver_ids: this.selectedDrivers.map((user) => user.id)
        }
        this.processing = true
      }
      this.buttonsDisabled = true
      this.axios
        .post('/v1/drivers-notifications', {
          ...payload
        })
        .then(() => {
          this.filter = {
            corporateId: null
          }
          this.notification = {
            notifyAll: false,
            title: '',
            description: ''
          }
          this.selectedDrivers = []
          this.$swal({
            icon: 'success',
            title: 'Notifications sent',
            text: 'All selected drivers were successfully notified',
            showCloseButton: true
          })
        })
        .catch((e) => {
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }
          this.errorMessage = msg
        })
        .finally(() => {
          this.processing = false
          this.processingAll = false
          this.buttonsDisabled = false
        })
    },
    prepareImageUpload (event) {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        this.notification.splashScreenImage = e.target.result
      }
      fileReader.readAsDataURL(event.target.files[0])
    },
    openImageSelector () {
      this.$refs.imageInput.click()
    },
    resetUIState () {
      this.filter = {
        corporateId: null
      }
      this.notification = {
        notifyAll: false,
        isFlash: false,
        splashScreenImage: null,
        title: '',
        description: ''
      }
      this.selectedDrivers = []
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/partials/sh-colors';
.selected-drivers {
  height: 100%;
  .card-header {
    max-height: 3.75rem;
  }
  .text-grey {
    color: sh-colors.$sh-neutral-600;
  }
}
</style>
